// To parse this data:
//
//   import { Convert, LoanProductsResponse } from "./file";
//
//   const loanProductsResponse = Convert.toLoanProductsResponse(json);

export interface LoanProductsResponse {
  productOptions?: ProductOption[];
}

export interface ProductOption {
  id?: number;
  name?: string;
  includeInBorrowerCycle?: boolean;
  useBorrowerCycle?: boolean;
  isLinkedToFloatingInterestRates?: boolean;
  isFloatingInterestRateCalculationAllowed?: boolean;
  allowVariableInstallments?: boolean;
  isInterestRecalculationEnabled?: boolean;
  canDefineInstallmentAmount?: boolean;
  principalVariationsForBorrowerCycle?: any[];
  interestRateVariationsForBorrowerCycle?: any[];
  numberOfRepaymentVariationsForBorrowerCycle?: any[];
  canUseForTopup?: boolean;
  isRatesEnabled?: boolean;
  multiDisburseLoan?: boolean;
  holdGuaranteeFunds?: boolean;
  accountMovesOutOfNPAOnlyOnArrearsCompletion?: boolean;
  syncExpectedWithDisbursementDate?: boolean;
  isEqualAmortization?: boolean;
}

// Converts JSON strings to/from your types
export class ConvertLoanProductsResponse {
  public static toLoanProductsResponse(json: string): LoanProductsResponse {
    return JSON.parse(JSON.stringify(json));
  }

  public static loanProductsResponseToJson(value: LoanProductsResponse): string {
    return JSON.stringify(value);
  }
}
