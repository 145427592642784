import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';

interface AutoLogoutProps {
  timeoutSeconds: number;
  warningDurationSeconds: number;
  onLogout: () => void;
}

const AutoLogout: React.FC<AutoLogoutProps> = ({ timeoutSeconds, warningDurationSeconds, onLogout }) => {
  const [showWarning, setShowWarning] = useState(false); // Show warning dialog
  const [countdown, setCountdown] = useState(warningDurationSeconds); // Countdown in seconds
  const logoutTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const warningTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const resetTimers = () => {
    if (logoutTimerRef.current) clearTimeout(logoutTimerRef.current);
    if (warningTimerRef.current) clearTimeout(warningTimerRef.current);

    // Hide warning and reset countdown
    setShowWarning(false);
    setCountdown(warningDurationSeconds);

    // Set warning timer
    warningTimerRef.current = setTimeout(() => {
      setShowWarning(true);

      // Start countdown
      const countdownInterval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      // Set logout timer
      logoutTimerRef.current = setTimeout(() => {
        clearInterval(countdownInterval);
        onLogout();
      }, warningDurationSeconds * 1000); // Convert seconds to milliseconds
    }, (timeoutSeconds - warningDurationSeconds) * 1000); // Convert seconds to milliseconds
  };

  const handleCancelLogout = () => {
    resetTimers();
  };

  useEffect(() => {
    // Events to detect user activity
    const events = ['mousemove', 'keydown', 'click', 'scroll'];
    const handleActivity = resetTimers;

    events.forEach((event) => window.addEventListener(event, handleActivity));
    resetTimers();

    // Cleanup on unmount
    return () => {
      events.forEach((event) => window.removeEventListener(event, handleActivity));
      if (logoutTimerRef.current) clearTimeout(logoutTimerRef.current);
      if (warningTimerRef.current) clearTimeout(warningTimerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={showWarning} onClose={handleCancelLogout}>
      <DialogTitle>Auto Logout Warning</DialogTitle>
      <DialogContent>
        <Typography>
          You have been inactive. You will be logged out in <strong>{countdown} seconds</strong>.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelLogout} color="primary" variant="contained">
          Cancel Logout
        </Button>
        <Button onClick={onLogout} color="secondary" variant="contained">
          Logout Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AutoLogout;
