import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { Member } from "../modules/Members/types/response/ListMembersResponse";
import { MemberTemplate, StaffOption } from "../modules/Members/types/MemberTemplate";
import { ClientIdentifiersTemplate } from "../modules/Members/types/ClientIdentifierTemplate";
import { SavingAccount } from "../modules/Savings/types/response/ListSavings";

export type NewMember = {
	id?: number;
	introducer?: StaffOption;
	rlshpOfficer?: StaffOption;
	memberNo: string;
	title: string;
	firstName: string;
	lastName: string;
	middleName?: string;
	displayName?: string;
	nationalities?: string[];
	preferedLanguages?: string;
	profileImage?: File | null;
	profileUrl?: string;
};

export type MemberStatus = {
	genderId?: number;
	dateOfBirth?: Date;
	maritalStatusId?: number;
};

export type MemberAddress = {
	id?: number | string;
	countryId?: number;
	stateProvinceId?: number;
	addressTypeId?: number;
	city?: string;
	addressLine1?: string;
	addressLine2?: string;
	addressLine3?: string;
	postalAddress?: string;
	postalCode?: string;
	physicalAddress?: string;
};

export type MemberIdentity = {
	primaryIdentityDocument?: number;
	idValue?: string;
	serialNumber?: string;
	kraPin?: string;
};

export type MemberProfession = {
	employmentType?: string,
	personOfInterests?: string[],
	professionId?: number,
	nameOfEmployer?: string,
	staffId?: string,
}

export type NextOfKin = {
	id?: string | number,
	names: string,
	phone?: string,
	relationship?: number,
}

export type MemberContact = {
	primaryNumber?: string,
	enableMbanking?: string;
	secondaryNumber?: string,
	emailAddress?: string,
	secondaryEmailaddress?: string;
}

export type MemberDocument = {
	id?: string | number,
	documentType?: string,
	file?: File;
	password?: string
}

export type MemberDocuments = {
	memberShipApplication?: MemberDocument;
	kraPinCertificate?: MemberDocument;
	signature?: MemberDocument;
	salarySlip?: MemberDocument;
	passportPhoto?: MemberDocument;
	frontId?: MemberDocument;
	backId?: MemberDocument;
}

export type JoiningFeeRef = {
	referenceNumber?: string,
}

export type MemberNotes = {
	notes?: string,
}

export type BstDirector = {
	id?: number,
	names: string,
	identityNumber?: string,
	serialNumber?: string,
	phone?: string,
	email?: string;
	displayName?: string;
	kraPin?: string;
	genderId?: number,
	dob?: string,
	documents?: MemberDocuments,
	country?: string | number;
	state?: string | number;
	postalCode?: string;
	postalAddress?: string;
	physicalAddress?: string;
}

export type BstInfo = {
	businessName?: string;
	businessDescription?: string;
	businessLocation?: string[];
	businessType?: string;
	businessHasPermit?: string;
	businessPIN?: string;
	businessPermitCertificate?: MemberDocument;
	personOfInterests?: string[]
}

export interface MemberInfo {
	selectedMember?: Member,
	memberTemplate?: MemberTemplate;
	clientIdentifierTemplate?: ClientIdentifiersTemplate;
	newMember?: NewMember,
	memberStatus?: MemberStatus,
	memberAddress?: MemberAddress,
	memberIdentity?: MemberIdentity,
	memberProfession?: MemberProfession,
	nextOfKins?: NextOfKin[],
	bstDirectors?: BstDirector[],
	bstInfo?: BstInfo,
	memberContact?: MemberContact,
	memberDocuments?: MemberDocuments,
	joiningFeeRef?: JoiningFeeRef,
	memberNotes?: MemberNotes,
	savingProduct?: SavingAccount,
	savingProducts?: SavingAccount[],
	savingInterval?: string;
	savingAmount?: number;
}

export interface MemberSlice {
	memberInfo?: MemberInfo
}

const initialState: MemberSlice = {};

const memberSlice = createSlice({
	name: "member",
	initialState,
	reducers: {
		setNewMember: (state, action: PayloadAction<MemberInfo>) => {
			state.memberInfo = { ...state.memberInfo, ...action.payload }
		},
		resetMember: (state) => {
			state.memberInfo = {
				memberTemplate: state.memberInfo?.memberTemplate,
				clientIdentifierTemplate: state.memberInfo?.clientIdentifierTemplate,
			}
		},
	},
});

export const { setNewMember, resetMember } = memberSlice.actions;

export default memberSlice.reducer;
