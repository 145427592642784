import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RescheduledLoan } from "../types/responses/RescheduledLoan";
import { LoanRepository } from "../../Loan/repository/Loan";

// Async thunk for fetching Rescheduled Loans
export const fetchRescheduledLoans = createAsyncThunk(
  "rescheduledLoans/fetch",
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const data = await LoanRepository.listRescheduleLoans(params);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to fetch rescheduled loans");
    }
  }
);

// Initial state
const initialState = {
  data: [] as RescheduledLoan[],
  loading: false,
  error: null as string | null,
};

// Slice
const rescheduledLoansSlice = createSlice({
  name: "rescheduledLoans",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRescheduledLoans.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRescheduledLoans.fulfilled, (state, action: PayloadAction<RescheduledLoan[]>) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchRescheduledLoans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default rescheduledLoansSlice.reducer;
