import { getAPIHeaders } from "../../../utils/helpers";
import AppConfig from "../../../lib/AppConfig";
import { cleanApiError, handleResponse } from "../../../utils/handleResponse";
import { MarkerChecker } from "../types/responses/MC";

export namespace MarkerCheckerRepository {
  // list
  export async function list(params: Record<string, any>): Promise<MarkerChecker[]> {
    try {
      const queryParams = new URLSearchParams(
        Object.entries(params)
          .reduce((acc, [key, value]) => {
            if (value !== undefined && value !== null && value !== "") {
              acc[key] = String(value);
            }
            return acc;
          }, {} as Record<string, string>)
      );

      const url = `${AppConfig.api_url}/fineract-provider/api/v1/makercheckers?${queryParams.toString()}`;

      const response = await fetch(url, {
        headers: getAPIHeaders(),
      });

      const data = await handleResponse(response);

      return data;
    } catch (error: any) {
      throw cleanApiError(error);
    }
  }

  // getById
  export async function getById(id: string | number): Promise<MarkerChecker> {
    try {
      const url = `${AppConfig.api_url}/fineract-provider/api/v1/audits/${id}`;

      const response = await fetch(url, {
        headers: getAPIHeaders(),
      });

      const data = await handleResponse(response);

      return data;
    } catch (error: any) {
      throw cleanApiError(error);
    }
  }

  // approve
  export async function approve(auditId: string): Promise<any> {
    try {
      const url = `${AppConfig.api_url}/fineract-provider/api/v1/makercheckers/${auditId}?command=approve`;

      const response = await fetch(url, {
        method: "POST",
        headers: getAPIHeaders(),
      });

      const data = await handleResponse(response);

      return data;
    } catch (error: any) {
      throw cleanApiError(error);
    }
  }

  // reject
  export async function reject(auditId: string): Promise<any> {
    try {
      const url = `${AppConfig.api_url}/fineract-provider/api/v1/makercheckers/${auditId}?command=reject`;

      const response = await fetch(url, {
        method: "POST",
        headers: getAPIHeaders(),
      });

      const data = await handleResponse(response);

      return data;
    } catch (error: any) {
      throw cleanApiError(error);
    }
  }

  // delete
  export async function deleteEntry(auditId: string): Promise<any> {
    try {
      const url = `${AppConfig.api_url}/fineract-provider/api/v1/makercheckers/${auditId}`;

      const response = await fetch(url, {
        method: "DELETE",
        headers: getAPIHeaders(),
      });

      const data = await handleResponse(response);

      return data;
    } catch (error: any) {
      throw cleanApiError(error);
    }
  }
}
