interface appConfig {
    api_url: string;
    projectId: string;
    tenantId: string;
    defaultPageSize: number;
    defaultRowsPerPageOptions: number[];
    autoHeight: boolean;
    tableHeight: any;
    shrinkTableHeight: any;
    mBorder: any;
    version: string;
    mInternalPadding: any;
    snackbarTimeout: number;
}

const AppConfig: appConfig = {
    api_url:
        process.env.NODE_ENV === "production"
            ? window.location.origin
            : process.env.REACT_APP_API_URL ?? "https://admins.tkb-kakuma.com",
    tenantId:
        process.env.NODE_ENV === "production"
            ? "kakuma"
            : process.env.REACT_APP_TENANT_ID ?? "default",
    projectId: "1",
    version: "V0.0.1",
    defaultPageSize: 50,
    defaultRowsPerPageOptions: [50, 100, 200, 400],
    autoHeight: false,
    tableHeight: "70vh",
    shrinkTableHeight: "50vh",
    mBorder: "1px solid rgb(216,216,216)",
    mInternalPadding: 1,
    snackbarTimeout: 3000,
};

export default AppConfig;
