// To parse this data:
//
//   import { Convert, LoanProduct } from "./file";
//
//   const loanProduct = Convert.toLoanProduct(json);

export type LoanProduct = {
  id?: number;
  name?: string;
  shortName?: string;
  description?: string;
  fundId?: number;
  fundName?: string;
  includeInBorrowerCycle?: boolean;
  useBorrowerCycle?: boolean;
  startDate?: number[];
  status?: string;
  currency?: Currency;
  principal?: number;
  minPrincipal?: number;
  maxPrincipal?: number;
  numberOfRepayments?: number;
  minNumberOfRepayments?: number;
  repaymentEvery?: number;
  repaymentFrequencyType?: AccountingRule;
  interestRatePerPeriod?: number;
  interestRateFrequencyType?: AccountingRule;
  annualInterestRate?: number;
  isLinkedToFloatingInterestRates?: boolean;
  isFloatingInterestRateCalculationAllowed?: boolean;
  allowVariableInstallments?: boolean;
  minimumGap?: number;
  maximumGap?: number;
  amortizationType?: AccountingRule;
  interestType?: AccountingRule;
  interestCalculationPeriodType?: AccountingRule;
  allowPartialPeriodInterestCalculation?: boolean;
  transactionProcessingStrategyId?: number;
  transactionProcessingStrategyName?: string;
  graceOnArrearsAgeing?: number;
  overdueDaysForNPA?: number;
  daysInMonthType?: AccountingRule;
  daysInYearType?: AccountingRule;
  isInterestRecalculationEnabled?: boolean;
  minimumDaysBetweenDisbursalAndFirstRepayment?: number;
  canDefineInstallmentAmount?: boolean;
  installmentAmountInMultiplesOf?: number;
  charges?: any[];
  principalVariationsForBorrowerCycle?: any[];
  interestRateVariationsForBorrowerCycle?: any[];
  numberOfRepaymentVariationsForBorrowerCycle?: any[];
  accountingRule?: AccountingRule;
  canUseForTopup?: boolean;
  isRatesEnabled?: boolean;
  rates?: any[];
  multiDisburseLoan?: boolean;
  maxTrancheCount?: number;
  disallowExpectedDisbursements?: boolean;
  allowApprovedDisbursedAmountsOverApplied?: boolean;
  overAppliedNumber?: number;
  principalThresholdForLastInstallment?: number;
  holdGuaranteeFunds?: boolean;
  accountMovesOutOfNPAOnlyOnArrearsCompletion?: boolean;
  allowAttributeOverrides?: AllowAttributeOverrides;
  syncExpectedWithDisbursementDate?: boolean;
  isEqualAmortization?: boolean;
  delinquencyBucketOptions?: any[];
  delinquencyBucket?: DelinquencyBucket;
}

export type AccountingRule = {
  id?: number;
  code?: string;
  value?: string;
}

export type AllowAttributeOverrides = {
  amortizationType?: boolean;
  interestType?: boolean;
  transactionProcessingStrategyId?: boolean;
  interestCalculationPeriodType?: boolean;
  inArrearsTolerance?: boolean;
  repaymentEvery?: boolean;
  graceOnPrincipalAndInterestPayment?: boolean;
  graceOnArrearsAgeing?: boolean;
  isNew?: boolean;
}

export type Currency = {
  code?: string;
  name?: string;
  decimalPlaces?: number;
  inMultiplesOf?: number;
  displaySymbol?: string;
  nameCode?: string;
  displayLabel?: string;
}

export type DelinquencyBucket = {
  id?: number;
  ranges?: any[];
}

// Converts JSON strings to/from your types
export class ConvertLoanProductResponse {
  public static toLoanProduct(json: string): LoanProduct {
    return JSON.parse(JSON.stringify(json));
  }

  public static loanProductToJson(value: LoanProduct): string {
    return JSON.stringify(value);
  }
}
