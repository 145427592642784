// To parse this data:
//
//   import { Convert, LoanTemplate } from "./file";
//
//   const loanTemplate = Convert.toLoanTemplate(json);

export interface LoanTemplate {
  includeInBorrowerCycle?: boolean;
  useBorrowerCycle?: boolean;
  currency?: Currency;
  repaymentFrequencyType?: AccountingRule;
  interestRateFrequencyType?: AccountingRule;
  isLinkedToFloatingInterestRates?: boolean;
  isFloatingInterestRateCalculationAllowed?: boolean;
  allowVariableInstallments?: boolean;
  amortizationType?: AccountingRule;
  interestType?: AccountingRule;
  interestCalculationPeriodType?: AccountingRule;
  daysInMonthType?: AccountingRule;
  daysInYearType?: AccountingRule;
  isInterestRecalculationEnabled?: boolean;
  interestRecalculationData?: InterestRecalculationData;
  canDefineInstallmentAmount?: boolean;
  principalVariationsForBorrowerCycle?: any[];
  interestRateVariationsForBorrowerCycle?: any[];
  numberOfRepaymentVariationsForBorrowerCycle?: any[];
  accountingRule?: AccountingRule;
  canUseForTopup?: boolean;
  isRatesEnabled?: boolean;
  fundOptions?: FundOption[];
  paymentTypeOptions?: PaymentTypeOption[];
  currencyOptions?: Currency[];
  repaymentFrequencyTypeOptions?: AccountingRule[];
  interestRateFrequencyTypeOptions?: AccountingRule[];
  amortizationTypeOptions?: AccountingRule[];
  interestTypeOptions?: AccountingRule[];
  interestCalculationPeriodTypeOptions?: AccountingRule[];
  transactionProcessingStrategyOptions?: TransactionProcessingStrategyOption[];
  chargeOptions?: ChargeOption[];
  accountingRuleOptions?: AccountingRule[];
  accountingMappingOptions?: AccountingMappingOptions;
  valueConditionTypeOptions?: AccountingRule[];
  daysInMonthTypeOptions?: AccountingRule[];
  daysInYearTypeOptions?: AccountingRule[];
  interestRecalculationCompoundingTypeOptions?: AccountingRule[];
  interestRecalculationNthDayTypeOptions?: AccountingRule[];
  interestRecalculationDayOfWeekTypeOptions?: AccountingRule[];
  rescheduleStrategyTypeOptions?: AccountingRule[];
  preClosureInterestCalculationStrategyOptions?: AccountingRule[];
  interestRecalculationFrequencyTypeOptions?: AccountingRule[];
  floatingRateOptions?: any[];
  holdGuaranteeFunds?: boolean;
  accountMovesOutOfNPAOnlyOnArrearsCompletion?: boolean;
  syncExpectedWithDisbursementDate?: boolean;
  isEqualAmortization?: boolean;
}

export interface AccountingMappingOptions {
  liabilityAccountOptions?: AccountOption[];
  assetAccountOptions?: AccountOption[];
  incomeAccountOptions?: AccountOption[];
  equityAccountOptions?: AccountOption[];
}

export interface AccountOption {
  id?: number;
  name?: string;
  parentId?: number;
  glCode?: string;
  disabled?: boolean;
  manualEntriesAllowed?: boolean;
  type?: AccountingRule;
  usage?: AccountingRule;
  description?: string;
  nameDecorated?: string;
  tagId?: TagID;
}

export interface TagID {
  id?: number;
  active?: boolean;
  mandatory?: boolean;
}

export interface AccountingRule {
  id?: number;
  code?: string;
  value?: string;
}

export interface ChargeOption {
  id?: number;
  name?: string;
  active?: boolean;
  penalty?: boolean;
  freeWithdrawal?: boolean;
  freeWithdrawalChargeFrequency?: number;
  restartFrequency?: number;
  restartFrequencyEnum?: number;
  isPaymentType?: boolean;
  currency?: Currency;
  amount?: number;
  chargeTimeType?: AccountingRule;
  chargeAppliesTo?: AccountingRule;
  chargeCalculationType?: AccountingRule;
  chargePaymentMode?: AccountingRule;
}

export interface Currency {
  code?: string;
  name?: string;
  decimalPlaces?: number;
  displaySymbol?: string;
  nameCode?: string;
  displayLabel?: string;
  inMultiplesOf?: number;
}

export interface FundOption {
  id?: number;
  name?: string;
}

export interface InterestRecalculationData {
  interestRecalculationCompoundingType?: AccountingRule;
  rescheduleStrategyType?: AccountingRule;
  isArrearsBasedOnOriginalSchedule?: boolean;
  isCompoundingToBePostedAsTransaction?: boolean;
  preClosureInterestCalculationStrategy?: AccountingRule;
  allowCompoundingOnEod?: boolean;
}

export interface PaymentTypeOption {
  id?: number;
  name?: string;
  description?: string;
  isCashPayment?: boolean;
  position?: number;
}

export interface TransactionProcessingStrategyOption {
  id?: number;
  code?: string;
  name?: string;
}

// Converts JSON strings to/from your types
export class ConvertLoanTemplate {
  public static toLoanTemplate(json: string): LoanTemplate {
    return JSON.parse(JSON.stringify(json));
  }

  public static loanTemplateToJson(value: LoanTemplate): string {
    return JSON.stringify(value);
  }
}
