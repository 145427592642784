import { FaTachometerAlt, FaUsers, FaMoneyCheckAlt, FaPiggyBank, FaCogs, FaBook } from 'react-icons/fa';
import { IRoute } from './types';

export default function useDashboardRoutes() {
  const routes: IRoute[] = [
    {
      key: "add-new",
      label: "Welcome",
      path: "/dashboard/cashier",
      icon: <FaTachometerAlt />,
    },
    {
      key: "dashboard",
      label: "Dashboard",
      path: "/analytics",
      icon: <FaTachometerAlt />,
    },
    {
      key: "members",
      label: "Members",
      path: "/members",
      icon: <FaUsers />,
    },
    {
      key: "loans",
      label: "Loans",
      path: "/loans",
      icon: <FaMoneyCheckAlt />,
    },
    {
      key: "savings",
      label: "Savings",
      path: "/savings",
      icon: <FaPiggyBank />,
    },
    {
      key: "tellers",
      label: "Tellers",
      path: "/tellers",
      icon: <FaBook />,
    },
    {
      key: "markercheckers",
      label: "Marker Checker",
      path: "/markercheckers",
      icon: <FaBook />,
    },
    {
      key: "accounting",
      label: "Accounting",
      icon: <FaCogs />,
      children: [
        {
          key: "reports1",
          label: "Transactions",
          path: "/transactions",
          icon: <FaMoneyCheckAlt />,
        },
        {
          key: "create_gl",
          label: "Create JE",
          path: "/transactions/create_gl",
          icon: <FaCogs />,
        },
      ],
    },
    {
      key: "reports",
      label: "Reports",
      icon: <FaCogs />,
      children: [
        {
          key: "reports1",
          label: "Reports",
          path: "/reports",
          icon: <FaCogs />,
        },
        {
          key: "bireports",
          label: "BI Reports",
          path: "/bireports",
          icon: <FaCogs />,
        },
      ],
    },
    {
      key: "system",
      label: "System",
      icon: <FaCogs />,
      children: [
        {
          key: "staff",
          label: "Staff",
          path: "/staff",
          icon: <FaUsers />,
        },
        {
          key: "roles",
          label: "Roles",
          path: "/roles",
          icon: <FaTachometerAlt />,
        },
        {
          key: "users",
          label: "Users",
          path: "/users",
          icon: <FaUsers />,
        },
      ],
    },
  ];

  return { routes };
}
