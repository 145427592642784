// To parse this data:
//
//   import { Convert, CreateClientResponse } from "./file";
//
//   const createClientResponse = Convert.toCreateClientResponse(json);

export interface CreateClientResponse {
  officeId?: number;
  clientId?: number;
  resourceId?: number;
}

// Converts JSON strings to/from your types
export class ConvertCreateClientResponse {
  public static toCreateClientResponse(json: string): CreateClientResponse {
    return JSON.parse(JSON.stringify(json));
  }

  public static createClientResponseToJson(value: CreateClientResponse): string {
    return JSON.stringify(value);
  }
}
