import axios from "axios";
import { getToken } from "../../utils/helpers";
import AppConfig from "../../lib/AppConfig";

const baseURL =
  process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : window.location.origin;

const axiosInstance = axios.create({
  baseURL: baseURL + "/fineract-provider/api/v1",
});

axiosInstance.interceptors.request.use(function (config) {
  const token = getToken();
  config.headers.Authorization = `Basic ${JSON.parse(token)}`.replaceAll('"', "");
  config.headers["fineract-platform-tenantid"] = AppConfig.tenantId;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      window.logout();
    }
    return await Promise.reject(error);
  }
);

export default axiosInstance;
