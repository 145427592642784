import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { MarkerCheckerRepository } from "../repository/MarkerChecker";
import { MarkerChecker } from "../types/responses/MC";

// Sample data for initial state
const sampleData: MarkerChecker[] = [];

// Async thunk for fetching MarkerChecker data
export const fetchMarkerCheckers = createAsyncThunk(
  "markerCheckers/fetch",
  async (params: Record<string, any>) => {
    const data = await MarkerCheckerRepository.list(params);
    return data;
  }
);

// Helper function to group data by entityName
function groupByEntityName(data: any[]) {
  return data.reduce((acc: Record<string, any[]>, item) => {
    const key = item.entityName || "Unknown";
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
}

// Helper function to group data by actionName
function groupByActionName(data: any[]) {
  return data.reduce((acc: Record<string, any[]>, item) => {
    const key = item.actionName || "Unknown";
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
}

// Helper function to get unique action names
function getUniqueActions(data: any[]) {
  return Array.from(new Set(data.map((item) => item.actionName)));
}

// Helper function to get unique action names
function getUniqueEntities(data: any[]) {
  return Array.from(new Set(data.map((item) => item.entityName)));
}

// Slice
const markerCheckerSlice = createSlice({
  name: "markerCheckers",
  initialState: {
    data: sampleData,
    actions: [] as string[],
    entities: [] as string[],
    groupedData: groupByEntityName(sampleData),
    groupedByAction: groupByActionName(sampleData),
    loading: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarkerCheckers.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchMarkerCheckers.fulfilled, (state, action: PayloadAction<any[]>) => {
        const sortedData = action.payload.sort((a, b) => b.id - a.id);
        state.data = sortedData;
        state.groupedData = groupByEntityName(sortedData);
        state.groupedByAction = groupByActionName(sortedData);
        const actions = getUniqueActions(sortedData)
        if (actions.length >= state.actions.length) {
          state.actions = actions;
        }
        const entities = getUniqueEntities(sortedData)
        if (entities.length >= state.entities.length) {
          state.entities = entities;
        }
        state.loading = false;
      })
      .addCase(fetchMarkerCheckers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch Marker Checkers";
      });
  },
});

export default markerCheckerSlice.reducer;
